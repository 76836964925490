@import '_variables.less';

.ant-table-pagination.ant-pagination {
  margin: 16px 24px;
}

.table-header {
  padding: 20px;
  .ant-row.ant-form-item {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
    align-items: flex-start;
    .ant-form-item-control {
      width: 100%;
    }
  }
}

.ant-select-selection-placeholder {
  padding-top: 4px !important;
}

