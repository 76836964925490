.container {
  border: none;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
  width: 50%;
  padding: 30px;
  :global {
    .ant-row {
      display: block;
    }
    .ant-col-8 {
      display: inline;
    }
    .ant-col-16 {
      max-width: 100%;
    }
  }
}
