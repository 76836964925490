.container-ip-collection {
  border: none;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);

  .btn-submit-ip {
    margin-left: 260px;
  }
  ::-webkit-file-upload-button {
    color: black;
    padding: 10px 1rem;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 7%);
    border: 1px solid #d9d9d9;
    cursor: pointer;
    background-color: #fff;
    font-weight: bold;
  }
  ::-webkit-file-upload-button:hover {
    color: #ed8f1c;
    border: 1px solid #dd5e0f;
  }
}
